import React, { useEffect } from "react";
import BookingInformation from "./booking-information";

const EReceipt = () => {
  return (
    <div>
        <BookingInformation />
    </div>
  );
};

export default EReceipt;
