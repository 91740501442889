import React, { useEffect, useState } from "react";
import styles from "./flight-route.module.scss";
import MultiCityAutosuggest from "../../multi-city/multi-city-autosuggest";
import { AirportData } from "../../../../models/models";
import { useDispatch, useSelector } from "react-redux";
import {
  formatDate,
  getLocalStorageData,
  setLocalStorageData,
} from "../../../../../../utils/helper";
import { RootState } from "../../../../slice/RootReducer";
import { useTranslation } from "react-i18next";
import moment from "moment";
import TGButton from "../../../../../../shared/tg-button";
import {
  resetBookFlightLabel,
  saveAccessedActiveTab,
  updateFieldData,
} from "../../../../slice/bookingWidgetSlice";
import TGDateCalendar from "../../../../../../shared/tg-new-datepicker";
import { config } from "../../../../../../config/global";
import { FlightScheduleTab } from "../../../../../../utils/enum-helper";
import useScreenSize from "../../../../../../utils/hook/useScreenSize";

interface Props {
  type?: string;
  handleCloseSidebar?: any;
}

const FlightRoute: React.FC<Props> = (props: Props) => {
  const { type } = props;
  const TGCalenderProps = {
    OneWay: {
      type: "1way",
      label: "depart",
    },
    Return: {
      type: "2way",
      label: "depart return",
    },
  };

  const [recentAirports, setRecentAirports] = useState({
    from: [],
    to: [],
  });
  const [value, setValue] = useState({
    from: {},
    to: {},
    date: {
      stDate: "",
      etDate: "",
    },
  });

  const [focused, setFocused] = useState({
    fromFocused:
      typeof window !== "undefined" &&
      !window?.location?.pathname?.includes("flightschedule")
        ? true
        : false,
    toFocused: false,
    dateFocused: false,
    date: false,
  });
  //This is used to update the know the status of date Data in the Flight schedule.
  const [date, setDate] = useState<any>({
    stDate: null,
    edDate: null,
  });
  const [tempDate, setTempDate] = useState<any>({
    stDate: null,
    edDate: null,
  });
  //This useEffect ensures that the Passenger Count and class state becomes reset when the selected AirportData is null

  const handleResetCalender = (label: string) => {
    dispatch(
      resetBookFlightLabel({
        fieldLabel: label
          .replace(/[^\w\s-]/g, "")
          .split(/[\s-]+/)
          .map((word: any, index: any) =>
            index === 0
              ? word.toLowerCase()
              : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          )
          .join(""),
      })
    );
  };
  //DateFormat change as per param needs
  const DateFormat = (value: any[]) => moment(value).format("YYYYMMDDHHmm");
  let e_date = DateFormat(date?.edDate);
  let b_date = DateFormat(date?.stDate);
  console.log(String(b_date), String(e_date), "DateFormat");

  //This function handles the onChange event for all kinds of inputs present in the booking widget like Autosuggest, Dropdown, Select, Calender etc.
  const handleChangeInput = (newValue: any, label: any) => {
    //This conversion is used to convert the labels send from onChange function to lowerCamelCase format.
    const fieldLabel = label
      .replace(/[^\w\s-]/g, "")
      .split(/[\s-]+/)
      .map((word: any, index: any) =>
        index === 0
          ? word.toLowerCase()
          : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      )
      .join("");
    dispatch(updateFieldData({ fieldLabel, newValue }));
    setDate(newValue);
    setValue({ ...value, date: { stDate: b_date, etDate: e_date } });
  };

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  let isDesktopView: boolean = useScreenSize().deviceSize.width > 767;
  const airportData: AirportData | any = useSelector(
    (state: RootState) => state?.osciReducer?.bookingWidget.airportData
  );
  const flightRouteDetailSelectedData = useSelector(
    (state: RootState) =>
      state?.osciReducer?.flightStatusRoute.flightRouteInputs
  );
  const tripData = getLocalStorageData("tripStatus");
  const savedTripData = JSON.parse(tripData) || { from: [], to: [] };
  const recentFromData = savedTripData?.from;
  const recentToData = savedTripData?.to;

  //Default Airport based on Location
  useEffect(() => {
    if (airportData?.length > 0) {
      const airportListBySelectedCountry = airportData?.filter(
        (x: AirportData) =>
          x?.countryCode?.toLowerCase() ===
          i18n.language?.split("-")?.[1]?.toLowerCase()
      );
      const defaultAirport = airportListBySelectedCountry?.filter(
        (z: AirportData) => z.isDefault
      );

      const arr = { ...value };
      arr.from = defaultAirport?.[0];
      setValue(arr);
    }
  }, [airportData]);

  useEffect(() => {
    let fromData = [];
    let toData = [];
    if (recentFromData && recentFromData?.length > 0) {
      fromData = recentFromData;
    }
    if (recentToData && recentToData?.length > 0) {
      toData = recentToData;
    }
    setRecentAirports({
      from: fromData,
      to: toData,
    });
  }, [recentFromData?.length, recentToData?.length]);

  useEffect(() => {
    if (flightRouteDetailSelectedData !== null) {
      setValue(flightRouteDetailSelectedData);
    }
  }, [flightRouteDetailSelectedData]);

  const createDateArray = (startDate: any, range: number) => {
    const dates = [];
    for (let i = -range; i <= range; i++) {
      const newDate = moment(startDate).add(i, "days");
      dates.push({
        id: i + range + 1,
        listName: formatDate(newDate, true),
        value: formatDate(newDate, false),
      });
    }
    return dates;
  };
  const startDate = moment();
  const dateArray = createDateArray(startDate, 2);

  const handleFieldChange = (x: any, name: string) => {
    let temp = { ...value, [name]: x };
    if (name === "from") {
      if (x?.airportCode === value?.to?.airportCode) {
        temp = {
          from: x,
          to: {},
        };
      }
    } else if (name === "to") {
      if (x?.airportCode === value?.from?.airportCode) {
        temp = {
          from: {},
          to: x,
        };
      }
    }
    setValue(temp);
    if (name === "from") {
      setFocused({ ...focused, fromFocused: false, toFocused: true });
    } else if (name === "to") {
      if (x?.airportCode === value?.from?.airportCode) {
        setFocused({ ...focused, toFocused: false, fromFocused: true });
      } else {
        setFocused({
          ...focused,
          toFocused: false,
          dateFocused: true,
          date: true,
        });
      }
    } else if (name === "date") {
      setFocused({
        ...focused,
        fromFocused: false,
        toFocused: false,
        dateFocused: false,
      });
    }
  };

  const handleSearch = () => {
    let tempData = {
      from: [...savedTripData?.from],
      to: [...savedTripData?.to],
    };
    const fromIndex =
      savedTripData?.from &&
      savedTripData?.from?.findIndex(
        (y: any) => y.airportCode === value?.from?.airportCode
      );
    const toIndex =
      savedTripData?.to &&
      savedTripData?.to?.findIndex(
        (y: any) => y.airportCode === value?.to?.airportCode
      );
    if (fromIndex < 0) {
      if (tempData?.from.length === 10) {
        tempData?.from?.pop();
      }
      tempData = {
        from: [value.from, ...tempData?.from],
        to: [...tempData?.to],
      };
    }
    if (toIndex < 0) {
      if (tempData?.to.length === 10) {
        tempData?.to?.pop();
      }
      tempData = {
        from: [...tempData?.from],
        to: [value.to, ...tempData?.to],
      };
    }

    setRecentAirports(tempData);
    setLocalStorageData("tripStatus", JSON.stringify(tempData));
    // dispatch(flightStatusRouteClear());
    // dispatch(
    //   flightStatusByRouteRequest({
    //     ...value,
    //     date: moment(value?.date).format("DDMMYY"),
    //   })
    // );
    // dispatch(flightStatusRouteInputSave(value));
    props?.handleCloseSidebar && props?.handleCloseSidebar(false);
    dispatch(saveAccessedActiveTab([3, "Route"]));

    // Amadeus Page Redirection Handled here
    let amadeusUrl =
      process.env.REACT_APP_TG_AMADEUS_URL + config.FLIGHT_SCHEDULE;
    let trip_type = type === "Return" ? "R" : "O";
    let amdeusParams = `b_date=${b_date}&e_date=${e_date}&b_location=${value?.from.airportCode}&e_location=${value?.to.airportCode}&trip_type=${trip_type}&lang=`;
    const amadeusRedirectionUrl = amadeusUrl + "?" + amdeusParams;
    window.open(amadeusRedirectionUrl, "_blank");
  };

  const handleClear = (name: string) => {
    let savedTrip = {
      from: [...savedTripData?.from],
      to: [...savedTripData?.to],
    };
    const newData = {
      ...savedTrip,
      [name]: [],
    };
    setRecentAirports(newData);
    setLocalStorageData("tripStatus", JSON.stringify(newData));
  };

  const handleSwap = () => {
    if (
      Object?.keys(value?.from)?.length > 0 &&
      Object?.keys(value?.to)?.length > 0
    ) {
      const tempToValue = value?.to;
      setValue({ ...value, from: tempToValue, to: value?.from });
    }
  };

  useEffect(() => {
    if (type === FlightScheduleTab.OneWay) {
      setTempDate(date);
      setDate((prevState: any) => {
        return {
          ...prevState,
          edDate: null,
        };
      });
    } else if (type === FlightScheduleTab.Return) {
      setDate((prevState: any) => {
        return {
          ...prevState,
          edDate: tempDate?.edDate,
        };
      });
    }
  }, [type]);
  return (
    <div className="d-flex flex-column">
      <div
        className={`${styles["flight-schedule-field-wrapper"]} d-flex gap-3 mt-3`}
      >
        <div
          className={`${
            styles[`flight-schedule-routes-dropdown-inputs-${type}`]
          }`}
        >
          <MultiCityAutosuggest
            label={t("label_book_widget_from")}
            customClassName="fromDropdownBorderRadius"
            onChange={() => {}}
            onSelect={handleFieldChange}
            searchPlaceHolder={t("label_multi_city_search_placeholder")}
            name="from"
            options={airportData}
            rowIndex={0}
            selectedPropsValue={value?.from}
            reverseArrow={true}
            onSwap={handleSwap}
            recentAirport={recentAirports?.from}
            onClearRecentSearch={() => handleClear("from")}
            isFocused={focused?.fromFocused}
            onClear={() => setValue({ ...value, from: {} })}
            onAutoSuggestBlur={() => {
              setFocused({
                ...focused,
                fromFocused: false,
                toFocused: false,
                dateFocused: false,
              });
            }}
            componentName="FSFrom"
            view={isDesktopView}
          />
          <MultiCityAutosuggest
            label={t("label_book_widget_to")}
            customClassName="toDropdownBorderRadius"
            onChange={() => {}}
            onSelect={handleFieldChange}
            searchPlaceHolder={t("label_multi_city_search_placeholder")}
            name="to"
            options={airportData}
            rowIndex={0}
            selectedPropsValue={value?.to}
            reverseArrow={false}
            onSwap={() => {}}
            recentAirport={recentAirports?.to}
            onClearRecentSearch={() => handleClear("to")}
            isFocused={focused?.toFocused}
            onAutoSuggestBlur={() => {
              setFocused({
                ...focused,
                fromFocused: false,
                toFocused: false,
                dateFocused: false,
                date: false,
              });
            }}
            onClear={() => setValue({ ...value, to: {} })}
            componentName="FSTo"
            view={isDesktopView}
          />
        </div>
        <div
          className={`${
            styles[`flight-schedule-routes-dropdown-datePicker-${type}`]
          }`}
        >
          <TGDateCalendar
            label={t("label_book_widget_depart")}
            onChange={(e, label) =>
              handleChangeInput(e, TGCalenderProps[type].label)
            }
            type={TGCalenderProps[type].type}
            dateData={date}
            setDateData={setDate}
            onReset={handleResetCalender}
            focused={focused}
            setFocused={setFocused}
            onDateBlur={() => {}}
            componentName="FlightSchedule"
            hideFare={true}
          />
        </div>
      </div>
      <div
        className={`${styles["flight-schedule-route-button"]} flight-schedule-route-button`}
      >
        <TGButton
          label={t("button_search_flights")}
          variant="primary"
          buttonSize="sm"
          customClass="bookingButtonCheckIn"
          onClick={handleSearch}
          disabled={
            Object.keys(value.from).length === 0 ||
            Object.keys(value.to).length === 0 ||
            value.date?.stDate === "" ||
            value.date?.stDate === undefined ||
            value.date === null
          }
        />
      </div>
    </div>
  );
};

export default FlightRoute;
