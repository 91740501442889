import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useScreenSize from "../../../../../utils/hook/useScreenSize";
import { AirportData, FlightStatusProps } from "../../../models/models";
import styles from "./flight-schedule.module.scss";
import { FlightScheduleTab } from "../../../../../utils/enum-helper";
import { airportDataRequest } from "../../../slice/bookingWidgetSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../slice/RootReducer";
import {
  getTripDataFailure,
  getTripDataSuccess,
} from "../../../slice/tripSlice";
import { config } from "../../../../../config/global";
import FlightRoute from "./flight-route";

const FlightSchedule: React.FC<FlightStatusProps> = (
  props: FlightStatusProps
) => {
  const { t, i18n } = useTranslation();
  const isDesktopView: boolean = useScreenSize().deviceSize.width > 768;
  const dispatch = useDispatch();
  const MANAGE_BOOKING_API_URL = config.TEAMSITE_UPCOMING_TRIPS_API;
  const airportData: AirportData | null = useSelector(
    (state: RootState) => state?.osciReducer?.bookingWidget.airportData
  );
  const savedTab = useSelector(
    (state: RootState) => state?.osciReducer?.bookingWidget?.tabIndex
  );

  useEffect(() => {
    window.scroll({ top: 300, behavior: "auto" });
  }, []);

  const flightScheduleTabs: any[] = [
    {
      value: FlightScheduleTab.Return,
      label: t("label_book_widget_return"),
    },
    {
      value: FlightScheduleTab.OneWay,
      label: t("label_book_widget_one_way"),
    },
  ];

  const [selectedItem, setSelectedItem] = useState<string>(
    FlightScheduleTab.Return
  );
  useEffect(() => {
    if (props.activeTab || savedTab?.[1]) {
      setSelectedItem(props.activeTab || savedTab?.[1]);
    }
  }, [props.activeTab]);

  useEffect(() => {
    if (!airportData) {
      dispatch(airportDataRequest(i18n.language?.split("-")?.[0]));
    }
  }, [airportData]);

  // useEffect(() => {
  //   dispatch({
  //     type: config.FETCH_DATA_CONSTANT,
  //     url: MANAGE_BOOKING_API_URL,
  //     isTeamsite: false,
  //     successAction: getTripDataSuccess,
  //     errorAction: getTripDataFailure,
  //   });
  // }, [MANAGE_BOOKING_API_URL, dispatch]);

  const handleTabSelection = (selectedTab: any) => {
    setSelectedItem(selectedTab);
  };

  const renderTabContent = () => {
    switch (selectedItem) {
      case "Return":
        return (
          <>
            <FlightRoute type={selectedItem} />
          </>
        );
      case "OneWay":
        return <FlightRoute type={selectedItem} />;

      default:
        return null;
    }
  };

  const renderDynamicTabContent = () => (
    <div className={styles["flight-schedule-container"]}>
      <div>
        {flightScheduleTabs.map((item: any) => (
          <button
            key={item}
            className={`${styles.item} ${
              selectedItem === item.value ? `${styles.selected}` : ""
            }`}
            onClick={() => handleTabSelection(item.value)}
            title={item.value}
            aria-label={`Flight schedule Tab ${item.value}`}
          >
            {item.label}
          </button>
        ))}
      </div>
      {isDesktopView && <div></div>}
    </div>
  );

  return (
    <>
      <div className={styles["flight-schedule-main-container"]}>
        <div>{renderDynamicTabContent()}</div>
        <div>{renderTabContent()}</div>
      </div>
      {/* {!props?.isHidden && tripData?.pnrDetails?.length > 0 &&  <FlightStatusUpcomingTrips />} */}
    </>
  );
};

export default FlightSchedule;
