import React, { useEffect, useState } from "react";
import TGIcon from "../../../../../../shared/tg-icon";
import TGButton from "../../../../../../shared/tg-button";
import TGInput from "../../../../../../shared/tg-input";
import TGModal from "../../../../../../shared/tg-modal";
import styles from "./updateSponsor.module.scss";
import { t } from "i18next";
import { Form, Spinner } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../slice/RootReducer";
import useScreenSize from "../../../../../../utils/hook/useScreenSize";
import { UpdateSponsorState } from "../../../../models/models";
import {
  fetchCountryRequest,
  fetchCityRequest,
  fetchStateRequest,
} from "../../../../slice/fetchProfileSlice";
// import countryData from "../../../../../public/json/country-nationality-code-flag.json";
// import stateData from "../../../../../public/json/statejsonmin.json";
// import cityData from "../../../../../public/json/city.json";
import { fetchMyProfileRequest } from "../../../../slice/fetchMainMemberProfileSlice";
import { isValidEmail } from "../../../../../../utils/helper";
import { config } from "../../../../../../config/global";
import { updateEReceiptDataRequest } from "../../../../slice/eReceiptSlice";
import TGTypeahead from "../../../../../../shared/tg-typeahead";

export const UpdateSponsor = (props: { show: any; handleClose: any }) => {
  const { show, handleClose } = props;
  const dispatch = useDispatch();
  const [profileData, setProfileData] = useState({
    sponsorName: "",
    revenueNo_TaxID: "",
    addressLine1: "",
    country: "",
    city: "",
    state: "",
    postalCode: "",
    countryCode: "",
    countryCodeSecondary: "",
  });

  type Country = {
    name: string;
    code: string;
    emoji: string;
    unicode: string;
    dial_code: string;
    image: string;
    nationality: string;
  };
  type State = {
    name: string;
    state_code: string;
    country_code: string;
  };
  type City = {
    country_code: string;
    country_name: string;
    city_code: string;
    name: string;
    state_code: string;
  };

  let isDesktopView: boolean = useScreenSize().deviceSize.width > 767;

  const [errors, setErrors] = useState<Partial<UpdateSponsorState>>({});
  const [memberId, setMemberId] = useState("");
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);
  const [selectedState, setSelectedState] = useState<State | null>(null);
  const [selectedCity, setSelectedCity] = useState<City | null>(null);
  const [selectedCountryCode, setSelectedCountryCode] =
    useState<Country | null>(null);
  const [filteredStates, setFilteredStates] = useState<State[]>([]);
  const [filteredCities, setFilteredCities] = useState<City[]>([]);
  const [isContactDisabled, setIsContactDisabled] = useState<boolean>(false);
  const [countryCodeValue, setCountryCodeValue] = useState(" ");
  const [isDisabled, setIsDisabled] = useState(true);
  const [isDisabledState, setIsDisabledState] = useState(true);
  const [isToggleOpen, setIsToggleOpen] = useState<string>("");
  const [countryData, setCountryData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [stateData, setStateData] = useState([]);

  const { isLoading, eReceiptBlob, error, profileInfo } = useSelector(
    (state: RootState) => state?.osciReducer?.eReceipt
  );

  const countryResponse = useSelector(
    (state: RootState) => state?.osciReducer?.profile.countryData
  );
  const cityResponse = useSelector(
    (state: RootState) => state?.osciReducer?.profile.cityData
  );
  const stateResponse = useSelector(
    (state: RootState) => state?.osciReducer?.profile.stateData
  );

  useEffect(() => {
    dispatch(fetchCountryRequest());
    dispatch(fetchCityRequest());
    dispatch(fetchStateRequest());
  }, [dispatch]);

  useEffect(() => {
    setCountryData(countryResponse);
    setCityData(cityResponse);
    setStateData(stateResponse);
  }, [cityResponse, countryResponse, stateResponse]);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  useEffect(() => {
    if (show) {
      document.documentElement.style.setProperty("--body-overflow", "hidden");
    } else {
      document.documentElement.style.setProperty("--body-overflow", "auto");
    }
    return () => {
      document.documentElement.style.setProperty("--body-overflow", "auto");
    };
  }, [show]);
  useEffect(() => {
    if (selectedCountryCode) {
      setCountryCodeValue(selectedCountryCode.dial_code);
    } else {
      if (selectedCountry) {
        setCountryCodeValue(selectedCountry.dial_code);
      }
      if (selectedCountry === null) {
        setCountryCodeValue("");
        setIsContactDisabled(false);
      }
    }
  }, [selectedCountry, profileData.country]);

  useEffect(() => {
    const countryCode = countryData?.find(
      (country) => country.name === profileData.country
    );
    setProfileData({
      ...profileData,
      countryCodeSecondary: countryCode?.code ? countryCode?.code : "",
    });
    setErrors({ ...errors, countryCodeSecondary: "" });
  }, [profileData.country]);

  useEffect(() => {
    if (selectedCountry) {
      setProfileData({
        ...profileData,
        countryCode: selectedCountry?.dial_code,
      });
      setErrors({ ...errors, countryCode: "" });
      const statePresent = stateData.filter(
        (state) => state.country_code === selectedCountry.code
      );
      if (statePresent?.length > 0) {
        setIsDisabled(false);
        setIsDisabledState(false);
      } else {
        setIsDisabledState(true);
        setIsDisabled(false);
      }

      setFilteredStates(
        stateData?.filter(
          (state) => state.country_code === selectedCountry.code
        )
      );

      setFilteredCities(
        cityData?.filter(
          (city: any) => city?.country_code === selectedCountry.code
        )
      );
    }
  }, [selectedCountry, profileData.country]);

  useEffect(() => {
    dispatch(fetchMyProfileRequest());
  }, [dispatch]);

  const profileResponse = useSelector(
    (state: RootState) => state?.osciReducer?.MyProfileDeails.myprofileData
  );

  useEffect(() => {
    const countryObj = countryData?.find(
      (country) => country.code === profileResponse?.address?.countryCode
    );

    const cityObj = cityData?.find(
      (city) =>
        city?.name?.toLowerCase() ===
        profileResponse?.address?.city?.toLowerCase()
    );

    const stateObj = stateData?.find(
      (state) =>
        state?.name?.toLowerCase() ===
        profileResponse?.address?.state?.toLowerCase()
    );

    setSelectedCountry(countryObj !== undefined ? countryObj : "");
    setSelectedState(stateObj !== undefined ? stateObj : "");
    setSelectedCity(cityObj !== undefined ? cityObj : "");

    setProfileData({
      ...profileData,
      addressLine1: profileResponse?.address?.addressLine1,
      country: countryObj?.name !== undefined ? countryObj?.name : "",
      countryCode: countryObj?.code !== undefined ? countryObj?.code : "",
      countryCodeSecondary:
        countryObj?.code !== undefined ? countryObj?.dial_code : "",
      city: profileResponse?.address?.city,
      state:
        profileResponse?.address?.state !== undefined
          ? profileResponse?.address?.state
          : "",
      postalCode: profileResponse?.address?.postalCode,
      addressType: profileResponse?.address?.addressType,
    });
  }, [profileResponse]);

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    if (name) {
      setProfileData({
        ...profileData,
        [name]: value,
      });
    }
    setErrors({ ...errors, [name]: "" });
  };
  const handleCountryChange = (selected: any) => {
    const country = selected?.length ? selected[0] : null;
    setSelectedCountry(country);
    setSelectedState(null);
    setSelectedCity(null);
    setProfileData({
      ...profileData,
      countryCode: country?.dial_code,
      country: country?.name,
      state: "",
      city: "",
    });

    setErrors({ ...errors, country: "" });
    setIsContactDisabled(true);
    setIsToggleOpen("");
  };
  const handleKeyDown = (e: any) => {
    const isValidInput = /^[a-zA-Z ]{0,100}$/.test(e.key);
    if (!isValidInput) {
      e.preventDefault();
    }
  };
  const handleCityChange = (selected: any) => {
    const city = selected?.length ? selected[0] : null;
    setSelectedCity(city);
    setProfileData({ ...profileData, city: city?.name });
    setErrors({ ...errors, city: "" });
    setIsToggleOpen("");
  };
  const handleStateChange = (selected: any) => {
    const state = selected.length ? selected[0] : null;
    setSelectedState(state);
    setProfileData({
      ...profileData,
      state: state?.name,
    });
    setErrors({ ...errors, state: "" });
    setIsToggleOpen("");
  };
  const getFieldLabel = (fieldName: keyof UpdateSponsorState) => {
    const labels: { [key in keyof UpdateSponsorState]: string } = {
      sponsorName: t("signin_email_required"),
    };
    return labels[fieldName];
  };

  const handleUpdateSponsor = (event: React.FormEvent<HTMLButtonElement>) => {
    const newErrors: Partial<UpdateSponsorState> = {};
    Object.entries(profileData).forEach(([key, value]) => {
      if (!value?.trim()) {
        newErrors[key as keyof UpdateSponsorState] = getFieldLabel(
          key as keyof UpdateSponsorState
        );
      } else if (
        (key === "sponsorName" && profileData.sponsorName?.length < 2) ||
        profileData.sponsorName === null ||
        profileData.sponsorName === ""
      ) {
        newErrors[key as keyof UpdateSponsorState] = t(
          "error_reset_sponsor_name_required"
        );
      }
    });
    setErrors(newErrors);
    var filterUndefinedError;
    const keys = Object.keys(newErrors) as (keyof UpdateSponsorState)[];
    filterUndefinedError = keys.filter((key) => newErrors[key] !== undefined);
    let updateSponsorUrl =
      process.env.REACT_APP_TG_API_BASE_URL + config.UPDATE_SPONSOR_API;
    const buildParams = (params: {}) => {
      return Object.keys(params)
        .filter((key) => params[key] !== undefined && params[key] !== null)
        .map((key: any) => {
          return key === "address_line2"
            ? `${key}=${params[key]}`
            : `${key}=${encodeURIComponent(params[key])}`;
        })
        .join("&");
    };
    const addressLine2 = [
      profileData?.city,
      profileData?.state,
      profileData?.country,
    ]
      .filter(Boolean)
      .join(",");
    const param = {
      company: profileData?.sponsorName,
      tkt_no: profileInfo?.bookingRef,
      tax_no: profileData?.revenueNo_TaxID || "",
      address_line1: profileData?.addressLine1 || "",
      address_line2: addressLine2 || "",
      address_line3: profileData?.postalCode || "",
    };
    let updateSponsorParams = buildParams(param);
    const updateSponsor = updateSponsorUrl + "?" + updateSponsorParams;
    if (
      profileData.sponsorName?.length > 2 &&
      profileData.sponsorName !== null
    ) {
      dispatch(updateEReceiptDataRequest(updateSponsor));
    }
  };

  return (
    <TGModal
      show={show}
      handleClose={handleClose}
      centered={true}
      customModalClass={styles.customModalClass}
      customOffCanvasClass="customOffCanvasClass"
      bodyClassName={styles["customOffCanvasClass"]}
      heading={!isDesktopView ? t("label_sponsor_info") : ""}
      backdrop={"static"}
    >
      <div>
        <div className={styles.container}>
          <div className={styles.sponsorInfo}>
            <div>
              <div className={styles.bookingDeatails}>
                <h6>
                  {t("label_name")}
                  {profileInfo?.length > 0
                    ? `${profileResponse?.firstName} ${profileResponse?.lastName}`
                    : `${profileInfo?.firstName} ${profileInfo?.lastName}`}
                </h6>
                <h6>
                  {t("labe_booking_reference")} {`${profileInfo?.bookingRef}`}
                </h6>
              </div>
              <h4>{t("label_sponsor_info")}</h4>
            </div>
            <div className={styles.banner}>
              <div>
                <TGIcon fillColor="" icon="warning-icon" size="16px" />
              </div>
              <div>
                <div>{t("label_sponsor_note")}</div>
              </div>
            </div>
            <div className={styles.sponsorDetails}>
              <div className={styles.inputFields}>
                <div>
                  <TGInput
                    isEnroll={true}
                    label={t("label_sponsorName")}
                    value={""}
                    name="sponsorName"
                    onChange={handleChange}
                    isError={!!errors.sponsorName}
                    errorLabel={
                      !!errors.sponsorName &&
                      profileData?.sponsorName?.length === 0
                        ? t("error_reset_sponsor_name_required")
                        : errors.sponsorName
                    }
                    customClassName={styles.fieldContainer}
                    validation="name"
                    maxLength={40}
                  />
                </div>
              </div>
              <div className={styles.inputFields}>
                <div>
                  <TGInput
                    isEnroll={true}
                    label={t("label_revenueNo_TaxID")}
                    value={""}
                    name="revenueNo_TaxID"
                    onChange={handleChange}
                    customClassName={styles.fieldContainer}
                    validation="number"
                    maxLength={40}
                  />
                </div>
              </div>
            </div>
            <div className={styles.profileContactInfo}>
              <div className={styles.profileContactInfoBodyContainer}>
                <div className={styles.profileContactInfoAddressContainer}>
                  <div
                    className={
                      styles.profileContactInfoBodyMailingAddressContainer
                    }
                  >
                    <div
                      className={styles.profileContactInfoBodyAddressContainer}
                    >
                      <div className={styles.profileContactInfoLabelContainer}>
                        <TGInput
                          isEnroll={true}
                          label={t("enroll_address_line1")}
                          value={profileData.addressLine1}
                          name="addressLine1"
                          onChange={handleChange}
                          placeholder="Example: House No., Alley, Road"
                          validation="address"
                        />
                      </div>
                    </div>
                    <div
                      className={styles.profileContactInfoBodyAddressContainer}
                    >
                      <Form.Group
                        style={{
                          flex: "1",
                          padding: "0",
                          width: !isDesktopView ? "100%" : "",
                        }}
                      >
                        <Form.Label className={styles.tgFormInputLabel}>
                          {t("enroll_country")}
                        </Form.Label>
                        <TGTypeahead
                          id="country"
                          labelKey={(option: any) => `${option.name}`}
                          options={countryData}
                          onChange={handleCountryChange}
                          selected={selectedCountry ? [selectedCountry] : []}
                          isOpen={isToggleOpen === "country"}
                          setIsOpen={(id) => setIsToggleOpen(id)}
                        />
                      </Form.Group>
                      <Form.Group
                        style={{
                          flex: "1",
                          padding: "0",
                          width: !isDesktopView ? "100%" : "",
                        }}
                      >
                        <Form.Label className={styles.tgEnrollInputLabel}>
                          {t("enroll_city")}
                        </Form.Label>

                        <TGTypeahead
                          id="city"
                          labelKey={(option: any) => `${option.name}`}
                          options={filteredCities}
                          onChange={handleCityChange}
                          selected={selectedCity ? [selectedCity] : []}
                          isOpen={isToggleOpen === "city"}
                          setIsOpen={(id) => setIsToggleOpen(id)}
                        />
                      </Form.Group>
                    </div>
                    <div
                      className={styles.profileContactInfoBodyAddressContainer}
                    >
                      <Form.Group
                        style={{
                          flex: "1",
                          padding: "0",
                          width: !isDesktopView ? "100%" : "",
                        }}
                      >
                        <Form.Label className={styles.tgEnrollInputLabel}>
                          {t("enroll_state")}
                        </Form.Label>
                        <TGTypeahead
                          id="state"
                          labelKey={(option: any) => `${option.name}`}
                          options={filteredStates}
                          onChange={handleStateChange}
                          selected={selectedState ? [selectedState] : []}
                          isOpen={isToggleOpen === "state"}
                          setIsOpen={(id) => setIsToggleOpen(id)}
                        />
                      </Form.Group>
                      <div
                        style={{
                          flex: "1",
                          padding: "0",
                          width: !isDesktopView ? "100%" : "",
                        }}
                      >
                        <TGInput
                          isEnroll={true}
                          label={t("enroll_postal")}
                          value={profileData.postalCode}
                          name="postalCode"
                          onChange={handleChange}
                          disables={isDisabled}
                          customClassName={styles.setFlex}
                          validation="postal"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.buttonContainer}>
              <div className={styles.button}>
                <TGButton
                  label={t("profile_cancel")}
                  variant="secondary"
                  onClick={handleClose}
                  customClassName={styles["sponsorButtons"]}
                />
              </div>
              <div className={styles.button}>
                <TGButton
                  label={isLoading ? "" : t("label_update")}
                  variant="primary"
                  onClick={handleUpdateSponsor}
                  customClassName={styles["sponsorButtons"]}
                >
                  {isLoading ? (
                    <>
                      <Spinner
                        animation="grow"
                        variant="light"
                        style={{
                          width: "10px",
                          height: "10px",
                          margin: "5px",
                        }}
                      />
                      <Spinner
                        animation="grow"
                        variant="light"
                        style={{
                          width: "10px",
                          height: "10px",
                          margin: "5px",
                        }}
                      />
                      <Spinner
                        animation="grow"
                        variant="light"
                        style={{
                          width: "10px",
                          height: "10px",
                          margin: "5px",
                        }}
                      />
                    </>
                  ) : null}
                </TGButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </TGModal>
  );
};
