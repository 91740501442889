import React, { useEffect, useRef, useState } from "react";
import TGButton from "../../../../../../shared/tg-button";
import styles from "./success-eReceipt.module.scss";
import TGIcon from "../../../../../../shared/tg-icon";
import { UpdateSponsor } from "../sponsor";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Worker } from "@react-pdf-viewer/core";
// Import the main component
import { Viewer } from "@react-pdf-viewer/core";
// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../slice/RootReducer";
import { config } from "../../../../../../config/global";
import {
  getEReceiptDataRequest,
  resetEreceiptDataRequest,
} from "../../../../slice/eReceiptSlice";
import TGButtonVariants from "../../../../../../shared/tg-button-variants";
import useScreenSize from "../../../../../../utils/hook/useScreenSize";
import { constant } from "../../../../../../config/constant";

const SuccessPage = () => {
  const viewRef = useRef<any>(null);
  const [width, setWidth] = useState<any>(null);
  const [isUpdateSponsor, setUpdateSponsor] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let isDesktopView: boolean = useScreenSize().deviceSize.width > 767;

  const { t, i18n } = useTranslation();
  const { contact_mail } = constant;
  const {
    isLoading,
    eReceiptBlob,
    eReceiptUrl,
    error,
    limitDetails,
    isUpdateSponsorSuccess,
  } = useSelector((state: RootState) => state?.osciReducer?.eReceipt);
  const updateSponsor = limitDetails?.sponsorUpdated === "true";
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const handlePrint = () => {
    window.open(eReceiptBlob, "PRINT", "height=600,width=800");
  };
  const handleDownload = () => {
    if (!eReceiptBlob) return;
    const link = document.createElement("a");
    link.href = eReceiptBlob;
    link.download = "e-receipt.pdf";
    link.click();
  };

  const handleUpdateSponsor = () => {
    setUpdateSponsor((prevState) => !prevState);
  };
  const sendMail = () => {
    window.location.href = process.env.REACT_APP_ERECEIPT_CONTACT_MAIL || "";
  };
  useEffect(() => {
    if (!eReceiptBlob) {
      navigate(`/${i18n.language}/e-Receipt`);
    }
  }, [eReceiptBlob]);
  useEffect(() => {
    if (isUpdateSponsorSuccess) {
      dispatch(getEReceiptDataRequest({ url: eReceiptUrl }));
      setUpdateSponsor(false);
    }
  }, [isUpdateSponsorSuccess]);

  let viewerWidth = viewRef?.current?.getBoundingClientRect().width;

  useEffect(() => {
    const intervalId = setInterval(() => {
      const textLayer = document.querySelector(".rpv-core__text-layer");
      if (textLayer) {
        const widthLength = textLayer.getBoundingClientRect().width;
        setWidth((viewerWidth - widthLength) / 2 + widthLength);
        clearInterval(intervalId);
      }
    }, 500);
    return () => clearInterval(intervalId);
  }, [viewerWidth]);

  return (
    <>
      <div className={`${styles.mainContainer} global-content-padding`}>
        <div className={styles.container}>
          <div>
            <TGIcon icon="green-check-icon" fillColor="" size="24px" />
          </div>
          <div className={styles["notification-outer-container"]}>
            <div className={styles.notificationContainer}>
              <div>
                {isDesktopView
                  ? t("label_eReceipt_success_notification")
                  : t("label_eReceipt_success_notification_mobile")}
              </div>
              <div>{t("label_eReceipt_request_limit")}</div>
            </div>
            <div
              className={styles.anotherReceipt}
              onClick={() => {
                dispatch(resetEreceiptDataRequest());
                navigate(`/${i18n.language}/e-Receipt`);
              }}
            >
              <div>
                <span> {t("label_another_eReceipt")}</span>
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.receiptContainer}`}>
          <div className={styles.sponserContainer}>
            <span>{t("label_update_sponser_header")}</span>
            <div>
              <TGButton
                label={t("label_update_sponser_button")}
                onClick={handleUpdateSponsor}
                variant="primary"
                customClassName={styles["update-sponsor-button"]}
                disabled={updateSponsor}
              />
              <div
                className={
                  updateSponsor
                    ? `${styles["updated-sponsor-note"]}`
                    : `${styles["update-sponsor-note"]}`
                }
              >
                {updateSponsor
                  ? t("label_contact").split("[contact]")[0]
                  : t("label_update_sponser_note")}
              </div>
              {updateSponsor && (
                <div className={styles["updated-sponsor-note"]}>
                  {t("label_contact").split("[contact]")[1]}
                  <span onClick={sendMail}>{contact_mail}</span>
                  {t("label_contact").split("[contact]")[2]}
                </div>
              )}
              {isUpdateSponsor && (
                <UpdateSponsor
                  show={isUpdateSponsor}
                  handleClose={handleUpdateSponsor}
                />
              )}
            </div>
          </div>
          <div className={styles.pdfContainer}>
            <div
              className={styles["subMain2_contentMain"]}
              style={{ width: width }}
            >
              <div className={styles["subMain2_contentMain_Btn"]}>
                <TGButton
                  label={t("label_print_eReceipt_button")}
                  onClick={handlePrint}
                  variant="secondary"
                  customClassName={styles.printReceipt}
                />
                <TGButton
                  label={t("label_download_eReceipt_button")}
                  onClick={handleDownload}
                  variant="primary"
                />
              </div>
            </div>
            {eReceiptBlob && (
              <div>
                <div ref={viewRef}>
                  <Worker workerUrl="/pdf.worker.min.js">
                    <div className={styles["pdf_Main_Content"]}>
                      <Viewer fileUrl={eReceiptBlob} />
                    </div>
                  </Worker>
                </div>
                <div className={styles["btn_Mobile"]}>
                  <TGButtonVariants
                    label={t("label_update_sponser_button")}
                    onClick={handleUpdateSponsor}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    borderRadius="30px"
                    boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                    bgColor="#F5EFFF"
                    padding="16px"
                    textColor="#684B9B"
                    fontFamily="Inter"
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight="700"
                    lineHeight="160%"
                    borderDisabled="0.791px solid #E3D4FE"
                    bgColorDisabled="#E3D4FE"
                    bgColorHover="#F5EFFF"
                    boxSHadowHover="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                    height="50px"
                    disabled={updateSponsor}
                  />
                  <TGButtonVariants
                    label={t("label_download_eReceipt_button")}
                    onClick={handleDownload}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    borderRadius="30px"
                    boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                    bgColor="#684B9B"
                    padding="16px"
                    textColor="#FFFFFF"
                    fontFamily="Inter"
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight="700"
                    lineHeight="160%"
                    borderDisabled="0.791px solid #E3D4FE"
                    bgColorDisabled="#E3D4FE"
                    boxShadowDisabled="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                    bgColorHover="#684b9b"
                    borderHover="0.791px solid #E3D4FE"
                    boxSHadowHover="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                    height="50px"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SuccessPage;
